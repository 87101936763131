@mixin is-sp {
  @media all and (max-width: '768px') {
    @content;
  }
}
@mixin is-pc {
  @media all and (min-width: '769px') {
    @content;
  }
}
@mixin sp-only {
  @include is-pc {
    display: none;
  }
}
@mixin pc-only {
  @include is-sp {
    display: none;
  }
}

@import "dl"
